import { Component, Vue } from "vue-property-decorator";
import service from "@/api/service";

@Component
export default class AppDownload extends Vue {

  result = 0;

  async created() {
    if(!this.$route.query.sid){
      this.result = 2;
      return false;
    }
    var req = {
      sid: this.$route.query.sid
    }
    
    const response = await service.get("/user/shop/appdownloadurl", req);

    if(response.data == "error" || !response.data){
      this.result = 2;
    }else{
      window.location = response.data;
    }
  }

}
